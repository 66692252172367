
import DateUtil from 'Utils/dateUtil';

export default [
    {
        title: 'LeaveType',
        key: '',
        minWidth: 80,
    },

	{
        title: 'Reason',
        key: 'Name',
        minWidth: 80,
    },

    
    {
        title: 'Date',
        key: 'statusname',
        minWidth: 80,
    },

]
